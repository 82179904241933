"use strict";
/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */
Object.defineProperty(exports, "__esModule", { value: true });
exports.bsModal = void 0;
var bsModal = /** @class */ (function () {
    function bsModal() {
        this.init();
    }
    bsModal.prototype.init = function () {
        // https://getbootstrap.com/docs/4.1/components/modal/
        /* $('.modal').on('shown.bs.modal', function (event: Event) {
            console.log("MODAL ABRIU!!!");
        }); */
        $('.modal').on('hidden.bs.modal', function (event) {
            var modal_notification = this.querySelector('.notification');
            var modal_notification_exists = this.contains(modal_notification);
            if (modal_notification_exists === true) {
                modal_notification.classList.remove('d-flex');
                modal_notification.classList.remove('fade-in');
                modal_notification.classList.remove('bg-sucesso');
                modal_notification.classList.remove('bg-danger');
                modal_notification.classList.add('d-none');
            }
            // console.log("MODAL FECHOU", modal_notification_exists);
        });
    };
    return bsModal;
}());
exports.bsModal = bsModal;
exports.default = bsModal;
